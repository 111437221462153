import { NavLink, Link } from 'react-router-dom';
import { navMenu } from '../data';
import { useState } from 'react';
const Navbar = () => {
    const [isMobileMenuopen, setIsMobileMenuOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
      console.log('togglePopup');
      setIsPopupOpen(!isPopupOpen);
    };
  
    const toggleMobileMenu = () => {
        console.log('toggleMobileMenu');
        setIsMobileMenuOpen(!isMobileMenuopen);
    };

    const submenuOpen = (menuName) => {
        console.log('submenuOpen');
        isSubmenuOpen === menuName
            ? setIsSubmenuOpen(null)
            : setIsSubmenuOpen(menuName);
    };

    const resetMobileMenu = () => {
        console.log('resetMobileMenu');
        setIsMobileMenuOpen(false);
        setIsSubmenuOpen(null);
    };
    return (
        <header className='main-header'>
            <div className='header-top'>
                <button
                    className='mobile-menu-button'
                    onClick={toggleMobileMenu}>
                    <i
                        className={
                            isMobileMenuopen ? 'fa fa-times' : 'fa fa-bars'
                        }
                        aria-hidden='true'></i>
                </button>
                <div className='header-image-container'>
                    <Link to='/' className='logo-link'>
                        <img
                            src='https://i.imgur.com/pGJ8sAJ.webp'
                            alt='Midland Orthopedic Associates'
                            className='header-image'
                            style={{ width: '300px', height: '79px' }}
                        />
                    </Link>
                </div>
                <div className='header-buttons-container'>
                    <NavLink className='btn header-button-dark' to='/locations'>
                        Call Us
                    </NavLink>
                    <div className='btn header-button-yellow'
                        onClick={togglePopup}
                    >
                        Download Forms
                    </div>
                </div>
                    {isPopupOpen && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <a
                                className='btn header-button-yellow'
                                style={{ fontSize: '0.9rem', zIndex: '10', borderRadius: '5px', boxShadow: '0.5px 0.5px 2px yellow', alignSelf: 'center', margin: '5px', alignSelf: 'center' }}
                                href={process.env.PUBLIC_URL + '/documents/Registration.pdf'}
                                target="_blank" // This opens the PDF in a new tab
                                rel="noopener noreferrer" // Recommended for security when using target="_blank"
                                download
                            >
                                Download Patient Registration Form
                            </a>
                            <a
                                className='btn header-button-yellow'
                                style={{
                                    fontSize: '0.9rem',
                                    zIndex: '10',
                                    borderRadius: '5px',
                                    boxShadow: '0.5px 0.5px 2px yellow',
                                    alignSelf: 'center',
                                    marginRight: '5px'
                                }}
                                href={process.env.PUBLIC_URL + '/documents/WorkersComp.pdf'}
                                target="_blank" // This opens the PDF in a new tab
                                rel="noopener noreferrer" // Recommended for security when using target="_blank"
                                download
                                >
                                Download Worker's Compensation Form
                            </a>
                        </div>
                    )}
            </div>
            <nav
                className={`navbar ${isSubmenuOpen}-open ${
                    isMobileMenuopen ? 'mobile-menu-show' : ''
                }`}>
                {navMenu.map((item, index) => {
                    return (
                        <div key={index} className={`nav-link-container ${item.menu}-nav`}>
                            <div className='link-items'>
                                <NavLink
                                    onClick={resetMobileMenu}
                                    key={item.menu}
                                    to={item.link}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }>
                                    {item.menu}
                                </NavLink>
                                {item.subMenuItems && (
                                    <button
                                        className='mobile-toggle-submenu'
                                        onClick={() => submenuOpen(item.menu)}>
                                        {isSubmenuOpen === item.menu ? (
                                            <i className='fas fa-minus'></i>
                                        ) : (
                                            <i className='fas fa-plus'></i>
                                        )}
                                    </button>
                                )}
                            </div>
                            {item.subMenuItems && (
                                <div className='submenu'>
                                    <NavLink
                                        onClick={resetMobileMenu}
                                        key={item.menu}
                                        to={item.link}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'sub-link mobile-nav-link active'
                                                : 'sub-link mobile-nav-link'
                                        }
                                        end>
                                        All {item.menu}
                                    </NavLink>
                                    <div className="submenu-list">
                                        {item.subMenuItems.map((subItem) => {
                                            return (
                                                // here
                                                <NavLink
                                                    onClick={resetMobileMenu}
                                                    key={subItem}
                                                    to={`${item.link}/${
                                                        item.menu === 'Physicians'
                                                        ? subItem
                                                        .toLowerCase()
                                                        .split(' ')[0]
                                                        : subItem
                                                                .toLowerCase()
                                                                .split(' ')
                                                                .join('-')
                                                                }`}
                                                                className={({ isActive }) =>
                                                                isActive
                                                                ? 'sub-link active'
                                                                : 'sub-link'
                                                            }>
                                                    {subItem}
                                                </NavLink>
                                                // here
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </nav>
        </header>
    );
};
export default Navbar;
